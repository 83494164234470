<template>
    <div ref="container" class="PostOffer_Genre">
        <page-header :title="`어떤 전문가가 필요하세요?`"></page-header>

        <div class="ui-border-line ui-h-0 ui-mb-1" />

        <tag-box>
            <li
                v-for="(row, key) in genreData.genres"
                :key="key"
                @click="() => (formData.genre = row)"
                :class="{ on: formData.genre === row }"
            >
                {{ $lib.t_genre(row) }}
            </li>
            <li
                @click="() => $refs.genreEtcModal.show()"
                :class="{
                    on: formData.genre === '직접입력' && !!formData.genreEtc,
                }"
            >
                직접입력
            </li>
        </tag-box>
        <modal
            ref="genreEtcModal"
            title="직접 입력해주세요"
            class="etc"
            :showCb="() => (modalData.genreEtc = formData.genreEtc)"
            confirm="등록"
            :confirmCb="
                (modal) => {
                    formData.genreEtc = modalData.genreEtc
                    formData.genre = '직접입력'
                    modal.hide()
                }
            "
        >
            <div slot="body">
                <div class="ui-inputbox etc">
                    <input
                        type="text"
                        v-model="modalData.genreEtc"
                        placeholder="예) 버스킹, 국악 등"
                    />
                </div>
            </div>
        </modal>
        <template v-if="!eventType.includes(postOffer.eventType) && category!=='개인' && postOffer.eventType!== '모델' && postOffer.mode !== 'direct' ">      
            <div class="select-tooltip">
                <check-box
                    text="모르겠어요. 다양한 공연팀 견적을 받고 싶어요!"
                    :click="
                        () => {
                            if (formData.genre === '공연팀') {
                                formData.genre = null
                            } else {
                                formData.genre = '공연팀'
                            }
                        }
                    "
                    :select="true"
                    :on="formData.genre === '공연팀'"
                >
                </check-box>
                <icon
                    class="select-tooltip-btn float-left"
                    src="img/lnb/tooltip.png"
                    width="2rem"
                    height="2rem"
                    :cover="false"
                    @click="() => showTooltip('select')"
                />
                <div
                    class="select-tooltip-toast toasted primary default"
                    :class="{ show: tooltip.select }"
                >
                    선택 시 가수, 댄스, DJ, 연주, 퍼포먼스 등등 <br> 다양한 공연팀에게
                    견적을 받을 수 있어요.
                </div>
            </div>
        </template>

        <template v-if="genreData.adminMemos.length > 0">
            <h4>바로 알아보기</h4>
            <tag-box>
                <li
                    v-for="(row, key) in genreData.adminMemos"
                    :key="key"
                    @click="() => searchArtistByAdminMemo(row)"
                >
                    {{ row }}
                </li>
            </tag-box>
        </template>

        <div class="ui-border-line ui-h-0 ui-mb-5" />

        <p class="postOffer-guideLetter">
            선택 Tip<br />
            마술,퓨전국악,뮤지컬 등은 '퍼포먼스'를 선택
        </p>

        <footer-box
            submitText="다음"
            :submitCb="() => nextStep()"
            :submitDisabled="!validate"
        ></footer-box>
    </div>
</template>

<script>
import PageHeader from '@/components/common/PageHeader'
import FooterBox from '@/components/common/FooterBox'
import Modal from '@/components/common/Modal'
import TagBox from '@/components/common/TagBox'
import CheckBox from '@/components/common/CheckBox.vue'
import Icon from '@/components/common/Icon'

export default {
    components: {
        PageHeader,
        FooterBox,
        Modal,
        TagBox,
        CheckBox,
        Icon,
    },
    data() {
        const postOffer = this.$store.state.postOffer
        const genreByEventType = this.$config.constant?.genreByEventType || []
        const genreData = genreByEventType.find((row) =>
            row.condition(postOffer.eventType)
        )
        const eventType = this.$config.constant.eventType.find(
            (row) => row.category == '개인'
        ).eventTypes
        const category = JSON.parse(
            sessionStorage.getItem('pageCache:PostOffer_EventType')
        ).modalData.inputCategory

        let genre = postOffer.genre
        let genreEtc = ''

        if (
            genre &&
            genreData?.genres?.indexOf?.(genre) === -1 &&
            genre !== '공연팀'
        ) {
            genreEtc = genre
            genre = '직접입력'
        }

        return {
            genreData: {
                genres: genreData?.genres || [],
                adminMemos: genreData?.adminMemos || [],
            },
            formData: {
                genre,
                genreEtc,
            },
            modalData: {
                genreEtc: '',
            },
            eventType,
            category,
            tooltip: {
                select: false,
            },
        }
    },
    computed: {
        step: {
            get() {
                return this.$route.params.step
            },
        },
        postOffer: {
            get() {
                return this.$store.state.postOffer
            },
            set(val) {
                this.$store.commit('setPostOffer', val)
            },
        },
        validate: {
            get() {
                return !!this.formData.genre
            },
        },
    },
    methods: {
        nextStep() {
            let genre =
                this.formData.genre !== '직접입력'
                    ? this.formData.genre
                    : this.formData.genreEtc
            this.postOffer = { genre }
            this.$parent.nextStep()
        },
        scrollDown() {
            this.$nextTick().then(() => {
                this.$refs?.container?.scroll?.({
                    top: this.$refs?.container?.scrollHeight || 0,
                    behavior: 'smooth',
                })
            })
        },
        searchArtistByAdminMemo(adminMemo) {
            this.$router.push({
                path: '/artist/list',
                query: {
                    adminMemo,
                    disableResetBackHistory: 'true',
                    hiddenSearchConditionController: 'true',
                    resetDataOnBack: 'true',
                    sort: 'view',
                },
            })
        },
        showTooltip(name) {
            this.tooltip[name] = true
            setTimeout(() => {
                this.tooltip[name] = false
            }, 2000)
        },
    },
}
</script>

<style lang="scss" scoped>
#page .PostOffer_Genre {
    h4 {
        font-size: 2rem;
        color: #000;
        margin-top: 50px;
        margin-bottom: 15px;
        font-weight: 400;
    }
}

.select-tooltip {
    position: relative;
    display: flex;
    align-items: center;
    .select-tooltip-toast {
        opacity: 0;
        position: absolute;
        bottom: -30rem;
        left: 0;
        right: 0;
        transition: 0.3s;
        line-height: normal;
        &.show {
            opacity: 1;
        }
    }

    .select-tooltip-btn {
        margin-left: 10px;
        margin-bottom: 2px;
    }
}
</style>
