var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "container", staticClass: "PostOffer_Genre" },
    [
      _c("page-header", { attrs: { title: "어떤 전문가가 필요하세요?" } }),
      _c("div", { staticClass: "ui-border-line ui-h-0 ui-mb-1" }),
      _c(
        "tag-box",
        [
          _vm._l(_vm.genreData.genres, function(row, key) {
            return _c(
              "li",
              {
                key: key,
                class: { on: _vm.formData.genre === row },
                on: {
                  click: function() {
                    return (_vm.formData.genre = row)
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.$lib.t_genre(row)) + " ")]
            )
          }),
          _c(
            "li",
            {
              class: {
                on: _vm.formData.genre === "직접입력" && !!_vm.formData.genreEtc
              },
              on: {
                click: function() {
                  return _vm.$refs.genreEtcModal.show()
                }
              }
            },
            [_vm._v(" 직접입력 ")]
          )
        ],
        2
      ),
      _c(
        "modal",
        {
          ref: "genreEtcModal",
          staticClass: "etc",
          attrs: {
            title: "직접 입력해주세요",
            showCb: function() {
              return (_vm.modalData.genreEtc = _vm.formData.genreEtc)
            },
            confirm: "등록",
            confirmCb: function(modal) {
              _vm.formData.genreEtc = _vm.modalData.genreEtc
              _vm.formData.genre = "직접입력"
              modal.hide()
            }
          }
        },
        [
          _c("div", { attrs: { slot: "body" }, slot: "body" }, [
            _c("div", { staticClass: "ui-inputbox etc" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.modalData.genreEtc,
                    expression: "modalData.genreEtc"
                  }
                ],
                attrs: { type: "text", placeholder: "예) 버스킹, 국악 등" },
                domProps: { value: _vm.modalData.genreEtc },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.modalData, "genreEtc", $event.target.value)
                  }
                }
              })
            ])
          ])
        ]
      ),
      !_vm.eventType.includes(_vm.postOffer.eventType) &&
      _vm.category !== "개인" &&
      _vm.postOffer.eventType !== "모델" &&
      _vm.postOffer.mode !== "direct"
        ? [
            _c(
              "div",
              { staticClass: "select-tooltip" },
              [
                _c("check-box", {
                  attrs: {
                    text: "모르겠어요. 다양한 공연팀 견적을 받고 싶어요!",
                    click: function() {
                      if (_vm.formData.genre === "공연팀") {
                        _vm.formData.genre = null
                      } else {
                        _vm.formData.genre = "공연팀"
                      }
                    },
                    select: true,
                    on: _vm.formData.genre === "공연팀"
                  }
                }),
                _c("icon", {
                  staticClass: "select-tooltip-btn float-left",
                  attrs: {
                    src: "img/lnb/tooltip.png",
                    width: "2rem",
                    height: "2rem",
                    cover: false
                  },
                  on: {
                    click: function() {
                      return _vm.showTooltip("select")
                    }
                  }
                }),
                _c(
                  "div",
                  {
                    staticClass: "select-tooltip-toast toasted primary default",
                    class: { show: _vm.tooltip.select }
                  },
                  [
                    _vm._v(" 선택 시 가수, 댄스, DJ, 연주, 퍼포먼스 등등 "),
                    _c("br"),
                    _vm._v(" 다양한 공연팀에게 견적을 받을 수 있어요. ")
                  ]
                )
              ],
              1
            )
          ]
        : _vm._e(),
      _vm.genreData.adminMemos.length > 0
        ? [
            _c("h4", [_vm._v("바로 알아보기")]),
            _c(
              "tag-box",
              _vm._l(_vm.genreData.adminMemos, function(row, key) {
                return _c(
                  "li",
                  {
                    key: key,
                    on: {
                      click: function() {
                        return _vm.searchArtistByAdminMemo(row)
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(row) + " ")]
                )
              }),
              0
            )
          ]
        : _vm._e(),
      _c("div", { staticClass: "ui-border-line ui-h-0 ui-mb-5" }),
      _vm._m(0),
      _c("footer-box", {
        attrs: {
          submitText: "다음",
          submitCb: function() {
            return _vm.nextStep()
          },
          submitDisabled: !_vm.validate
        }
      })
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "postOffer-guideLetter" }, [
      _vm._v(" 선택 Tip"),
      _c("br"),
      _vm._v(" 마술,퓨전국악,뮤지컬 등은 '퍼포먼스'를 선택 ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }